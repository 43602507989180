<template>
	<v-container fluid class="pa-2 main gridContainer">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
	
		<div class="toolbar">
			<v-row align="center">
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					class="mr-3"
					dense
					outlined
					hide-details
					@input="onSearchInput"
				></v-text-field>
				<v-btn class="btn blue" elevation="0" dark @click="addRegion()">
					<v-icon dark>mdi-plus</v-icon>
					&nbsp;Add Region
				</v-btn>
			</v-row>
		</div>

		<v-data-table
			:headers="headers"
			:items="regions"
			:page.sync="page"
			item-key="id"
			:items-per-page="itemsPerPage"
			hide-default-footer
			class="customTable"
			@click:row="showDetails"
			:loading="loading"
			:server-items-length="regionsCount"
			loading-text="Loading... Please wait"
		>
			<template #no-data>
				<div class="text-center">
					<h5>No regions found</h5>
				</div>
			</template>

			<template #[`item.title`]="{ item }">
				<div>{{ item.fields?.title?.de }}</div>
			</template>

			<template #[`item.id`]="{ item }">
				<v-label>{{ item.sys.id }}</v-label>
			</template>

			<template #[`item.createdDate`]="{ item }">
				<div>{{ formatDate(item.sys.createdAt) }}</div>
			</template>
		</v-data-table>

		<div class="text-center pt-2 paginator">
			<v-pagination v-model="page" :length="pageCount" @input="onPageChange"></v-pagination>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'
import moment from 'moment'

export default {
	name: 'RegionsView',
	components: { Loading, Alert },
	mixins: [Common],

	data() {
		return {
			search: '',
			searchTimeout: null,
			page: 1,
			itemsPerPage: 15,
			regionsCount: 0,
			regions: [],

			headers: [
				{ text: 'Title', value: 'title', sortable: false },
				{ text: 'ID', value: 'id', sortable: false },
				{ text: 'Created', value: 'createdDate', sortable: false }
			]
		}
	},

	computed: {
		pageCount() {
			return Math.ceil(this.regionsCount / this.itemsPerPage)
		}
	},

	created() {
		// Reset any previously stored search parameters
		this.search = ''
		this.page = 1
	},

	mounted() {
		this.getRegions()
	},

	beforeRouteEnter(to, from, next) {
		// This ensures data is fetched when navigating to this route
		next(vm => {
			vm.getRegions()
		})
	},

	methods: {
		async getRegions() {
			if (this.loading) return
			
			this.loading = true

			try {
				const skip = (this.page - 1) * this.itemsPerPage
				
				// Build URL with query parameters directly instead of using params object
				let url = `/regions?skip=${skip}&limit=${this.itemsPerPage}`
				if (this.search) {
					url += `&searchString=${encodeURIComponent(this.search)}`
				}

				const result = await this.$httpGet(url)
				this.regions = result.regions
				this.regionsCount = result.total
			} catch (error) {
				this.showError(error)
			}

			this.loading = false
		},

		onPageChange() {
			this.getRegions()
		},

		onSearchInput() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
			}

			this.searchTimeout = setTimeout(() => {
				this.page = 1
				this.getRegions()
			}, 500)
		},

		addRegion() {
			this.$router.push({
				path: '/region-detail',
				query: { action: 'CREATE' }
			})
		},

		showDetails(region) {
			this.$store.commit('setSelectedRegion', region)
			this.$router.push({
				path: '/region-detail',
				query: { action: 'VIEW' }
			})
		},

		formatDate(date) {
			return moment(date).format('DD.MM.YYYY HH:mm:ss')
		}
	}
}
</script>

<style scoped>
.toolbar {
	margin-top: 20px;
	margin-bottom: 10px;
}

.customTable {
	cursor: pointer;
}

.paginator {
	margin-top: 20px;
}
</style> 