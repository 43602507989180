<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ action === this.Action.CREATE ? 'Create New Region' : 'Region Detail View' }}</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block" v-if="userMayEditFields()">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="saveRegion()">Save Region</v-btn>
					<v-btn 
						v-if="action !== this.Action.CREATE" 
						block 
						class="btn red mt-3" 
						elevation="0" 
						dark 
						@click="confirmDelete()"
					>
						<v-icon left>mdi-delete</v-icon>
						Delete Region
					</v-btn>
				</div>
			</div>

			<div class="sidebar-block" v-if="action !== this.Action.CREATE">
				<p class="sidebar-title">Info</p>
				id: <v-label v-if="region?.sys">{{ region.sys.id }}</v-label>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="true" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Region Title</v-label>
					<div v-for="locale in locales" :key="locale.code" class="mb-4">
						<div class="d-flex align-center">
							<div class="d-flex align-center" style="width: 10%">
								<LanguageFlag :value="locale.code" class="mr-2" />
								<v-label>{{ locale.name }}</v-label>
							</div>
							<div style="width: 90%">
								<v-text-field 
									outlined 
									hide-details 
									:disabled="!userMayEditFields()"
									v-model="region.fields.title[locale.code]"
								/>
							</div>
						</div>
					</div>
				</div>
			</Disclosure>
		</div>
		
		<!-- Sync to CH2 options -->
		<div class="pa-3" style="width: 100%" v-if="userMayEditFields()">
			<Disclosure title="Content Hub Sync Options" :expanded="true">
				<div class="field left-border">
					<v-switch
						v-model="syncToCh2"
						label="Sync to Content Hub 2"
						hide-details
					/>
				</div>

				<div class="field left-border" v-if="syncToCh2">
					<div class="sync-warning mb-3">
						<v-icon color="amber darken-2" small class="mr-1">mdi-alert-circle</v-icon>
						<span>This region will be exported to Content Hub for the selected client. For an existing region, if the entry already exists in Content Hub for the selected client, it will be updated.</span>
					</div>
					
					<div v-if="mappedClientInfo" class="mapped-client-info mb-3">
						<v-icon color="primary" small class="mr-1">mdi-information</v-icon>
						<span>This region is used in region mappings for client: <strong>{{ mappedClientInfo }}</strong>. Therefore, we pre-selected the client for which the entry will be updated in Content Hub.</span>
					</div>
					
					<v-autocomplete
						v-model="selectedClientId"
						:items="clients"
						item-text="title"
						item-value="id"
						label="Select Client"
						outlined
						:disabled="!syncToCh2"
						:rules="[v => !!v || 'Client is required for syncing']"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Region Mappings -->
		<div v-if="action !== this.Action.CREATE && regionMappings.length > 0" class="pa-3" style="width: 100%">
			<Disclosure title="Region Mappings" :expanded="true">
				<div class="field left-border">
					<div class="info-message mb-3">
						<v-icon color="primary" small class="mr-1">mdi-information</v-icon>
						<span>This region is used in {{ regionMappings.length }} region mapping(s). If you delete this region, these mappings will need to be updated because they reference this region.</span>
					</div>
					<v-list>
						<v-list-item v-for="mapping in regionMappings" :key="mapping.sys.id">
							<v-list-item-content>
								<v-list-item-title>
									{{ mapping.fields.title.de }}
								</v-list-item-title>
								<v-list-item-subtitle>
									Client: {{ getClientName(mapping.fields.client?.de?.sys?.id) || 'Unknown' }}
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn small text color="primary" @click="viewRegionMapping(mapping)">
									<v-icon small>mdi-eye</v-icon>
									View
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</div>
			</Disclosure>
		</div>

		<!-- Audit Log -->
		<div v-if="action !== this.Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="region.sys.id" :contentType="'region'"/>
		</div>

		<!-- Delete Confirmation Dialog -->
		<v-dialog v-model="showDeleteDialog" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					<v-icon large color="error" class="mr-2">mdi-alert-circle</v-icon>
					Delete Region
				</v-card-title>
				<v-card-text>
					<div v-if="regionMappings.length > 0" class="warning-message mb-4">
						<v-icon color="amber darken-2" small class="mr-1">mdi-alert-circle</v-icon>
						<span>
							<strong>Warning:</strong> This region is used in {{ regionMappings.length }} region mapping(s). 
							Deleting this region will cause these mappings to reference a non-existent region.
						</span>
					</div>

					<div v-if="regionMappings.length > 0" class="mb-4 pa-3 affected-mappings">
						<p class="subtitle-1 mb-2"><strong>Affected Region Mappings:</strong></p>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>Title</th>
										<th>ID</th>
										<th>Client</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="mapping in regionMappings" :key="mapping.sys.id">
										<td>{{ mapping.fields.title.de }}</td>
										<td><code>{{ mapping.sys.id }}</code></td>
										<td>{{ getClientName(mapping.fields.client?.de?.sys?.id) || 'Unknown' }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>

					<div>
						Are you sure you want to delete this region? This action cannot be undone.
						<p class="mt-2">
							<strong>Title:</strong> {{ region.fields.title.de }}
						</p>
						<div v-if="selectedClientId && syncToCh2" class="warning-message mt-2">
							<v-icon color="amber darken-2" small class="mr-1">mdi-alert-circle</v-icon>
							<span>The region will also be deleted from Content Hub for the selected client: <strong>{{ getClientName(selectedClientId) || 'Unknown' }}</strong></span>
						</div>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="showDeleteDialog = false">
						Cancel
					</v-btn>
					<v-btn 
						color="error" 
						@click="deleteRegion()"
					>
						Delete
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Common from '@/mixins/Common.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import _ from 'lodash'

export default {
	name: "RegionDetailView",
	components: { 
		Loading, 
		SideBar, 
		Alert, 
		Disclosure, 
		AuditLog,
		LanguageFlag
	},
	mixins: [ Common ],

	props: {
		initialAction: { type: String },
		regionId: { type: String }
	},

	data() {
		return {
			Action: {
				CREATE: 'CREATE',
				VIEW: 'VIEW',
				EDIT: 'EDIT'
			},
			action: this.initialAction,
			region: {
				sys: {
					id: '',
					contentType: {
						sys: { id: 'region' }
					}
				},
				fields: {
					title: {
						de: '',
						en: '',
						fr: '',
						it: ''
					}
				}
			},
			runValidation: false,
			syncToCh2: true,
			selectedClientId: null,
			clients: [],
			regionMappings: [],
			mappedClientInfo: null,
			showDeleteDialog: false
		}
	},
	
	computed: {
	},

	async mounted() {
		this.loading = true
		
		// Get locales if not already available
		if (!this.locales?.length) {
			await this.getLocales()
		}
		
		// Get clients for CH2 sync
		await this.getClients()
		
		// For direct access by ID or edit mode, get the region details
		if (this.regionId) {
			// Direct access via URL with ID
			this.action = this.Action.VIEW
			await this.getRegionDetailsById(this.regionId)
		} else if (this.action === this.Action.VIEW) {
			// Access via store selection
			await this.getRegionDetails()
		}
		
		// Try to find region mappings for this region
		if (this.region?.sys?.id) {
			await this.getRegionMappings()
		}
		
		this.loading = false
	},

	methods: {
		async getRegionDetailsById(id) {
			try {
				this.region = await this.$httpGet(`/regions/${id}`)
				
				// Also update the store
				this.$store.commit('setSelectedRegion', this.region)
			} catch (error) {
				this.showError(error)
				// Navigate back to list on error
				setTimeout(() => this.goback(), 2000)
			}
		},
		
		async getRegionDetails() {
			if (!this.$store.state.selectedRegion?.sys?.id) return
			
			try {
				this.region = await this.$httpGet(`/regions/${this.$store.state.selectedRegion.sys.id}`)
			} catch (error) {
				this.showError(error)
			}
		},
		
		async getClients() {
			try {
				const response = await this.$httpGet('/clients')
				this.clients = response.clients.map(client => ({
					id: client.sys.id,
					title: client.fields.title.de
				}))
			} catch (error) {
				this.showError(error)
			}
		},
		
		async getRegionMappings() {
			try {
				// Get region mappings where this region is used
				const filter = { region: this.region.sys.id }
				const encodedFilter = encodeURIComponent(JSON.stringify(filter))
				const response = await this.$httpGet(`/region-mappings?filter=${encodedFilter}`)
				
				if (response.regionMappings && response.regionMappings.length > 0) {
					this.regionMappings = response.regionMappings
					
					// Get the client from the first mapping that has a client
					for (const mapping of this.regionMappings) {
						if (mapping.fields.client?.de?.sys?.id) {
							const clientId = mapping.fields.client.de.sys.id
							
							// Find the client in our list to get its title
							const clientInfo = this.clients.find(c => c.id === clientId)
							if (clientInfo) {
								this.mappedClientInfo = clientInfo.title
								this.selectedClientId = clientId
								break
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching region mappings:', error)
				// Don't show error to user as this is just an enhancement
			}
		},
		
		async saveRegion() {
			if (!this.region.fields.title.de) {
				this.errorTitle = 'VALIDATION ERROR'
				this.errorDetail = 'Please enter the required information'
				return
			}
			
			this.loading = true
			
			try {
				// Add sync information if needed
				const payload = _.cloneDeep(this.region)
				
				if (this.syncToCh2) {
					payload.syncToCh2 = this.syncToCh2
					payload.clientId = this.selectedClientId
				}
				
				let response
				if (this.action === this.Action.CREATE) {
					response = await this.$httpPost('/regions', payload)
				} else {
					response = await this.$httpPut(`/regions/${this.region.sys.id}`, payload)
				}
				
				// Update store and local state
				this.$store.commit('setSelectedRegion', response)
				this.region = response
				
				// Update action to VIEW if it was CREATE
				if (this.action === this.Action.CREATE) {
					this.action = this.Action.VIEW
					// Update URL
					this.$router.replace({
						path: '/region-detail',
						query: { action: 'VIEW' }
					})
				}
				
				this.successTitle = 'SUCCESS'
				this.successDetail = 'Region saved successfully'
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		
		confirmDelete() {
			this.showDeleteDialog = true
		},
		
		async deleteRegion() {
			this.loading = true
			this.showDeleteDialog = false
			
			try {
				// Create query parameters instead of request body
				const queryParams = {
					syncToCh2: this.syncToCh2 ? 'true' : 'false',
					clientId: this.selectedClientId || ''
				}
				
				console.log('Sending delete query params:', queryParams)
				
				// Store region mapping details before deletion
				let affectedMappings = []
				if (this.regionMappings.length > 0) {
					affectedMappings = this.regionMappings.map(mapping => ({
						title: mapping.fields.title.de,
						id: mapping.sys.id,
						client: this.getClientName(mapping.fields.client?.de?.sys?.id) || 'Unknown'
					}))
				}
				
				const deleteResponse = await this.$httpDelete(`/regions/${this.region.sys.id}`, { query: queryParams })
				
				this.successTitle = 'REGION DELETED'
				
				if (deleteResponse.hasRegionMappings) {
					// Store the affected mappings in sessionStorage for reference
					if (affectedMappings.length > 0) {
						sessionStorage.setItem('deletedRegionMappings', JSON.stringify(affectedMappings))
					}
					
					this.successDetail = `Region deleted successfully, but note that ${deleteResponse.regionMappingsCount} region mapping(s) were referencing this region. The affected mappings are listed below for your reference.`
					
					// Create a formatted list of affected mappings
					setTimeout(() => {
						const alertEl = document.querySelector('.v-alert__content')
						if (alertEl && affectedMappings.length > 0) {
							const listEl = document.createElement('div')
							listEl.className = 'mt-2 affected-mappings-list'
							listEl.innerHTML = `
								<p class="mb-1"><strong>Affected Region Mappings:</strong></p>
								<ul class="pl-4">
									${affectedMappings.map(m => `<li><strong>${m.title}</strong> (ID: ${m.id}, Client: ${m.client})</li>`).join('')}
								</ul>
							`
							alertEl.appendChild(listEl)
						}
					}, 100)
				} else {
					this.successDetail = 'Region deleted successfully'
				}
				
				setTimeout(() => { this.goback() }, 5000) // Give more time to see the affected mappings
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		
		getClientName(clientId) {
			if (!clientId) return null
			const client = this.clients.find(c => c.id === clientId)
			return client ? client.title : null
		},
		
		viewRegionMapping(mapping) {
			// Store the mapping in the store for backward compatibility
			this.$store.commit('setSelectedRegionMapping', mapping)
			
			// Navigate using the deep link
			this.$router.push(`/regionmappings/${mapping.sys.id}`)
		},
		
		goback() {
			this.$router.push('/regions')
		}
	}
}
</script>

<style scoped>
.sidebar-block {
	padding: 16px;
	border-bottom: 1px solid #e0e0e0;
}

.sidebar-title {
	font-weight: bold;
	margin-bottom: 10px;
}

.buttonBlock {
	margin-top: 16px;
}

.mandatory {
	color: red;
}

.sync-warning {
	font-size: 14px;
	color: #b58e00;
	background-color: #fff8e1;
	padding: 8px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.mapped-client-info {
	font-size: 14px;
	color: #1976d2;
	background-color: #e3f2fd;
	padding: 8px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.info-message {
	font-size: 14px;
	color: #1976d2;
	background-color: #e3f2fd;
	padding: 8px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.error-message {
	font-size: 14px;
	color: #d32f2f;
	background-color: #ffebee;
	padding: 8px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.warning-message {
	font-size: 14px;
	color: #b58e00;
	background-color: #fff8e1;
	padding: 8px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.affected-mappings {
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	background-color: #fafafa;
}

.affected-mappings code {
	background-color: #f0f0f0;
	padding: 2px 4px;
	border-radius: 3px;
	font-size: 12px;
}

.affected-mappings-list {
	margin-top: 12px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 8px;
}

.affected-mappings-list ul {
	max-height: 150px;
	overflow-y: auto;
	margin: 0;
}

.affected-mappings-list li {
	margin-bottom: 4px;
	font-size: 13px;
}
</style> 