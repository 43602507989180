import Vue from 'vue'
import Vuex from 'vuex'
import localforage from 'localforage'

Vue.use(Vuex)

const db = localforage.createInstance({
	name: "MYS_Toolbox",
	driver: localforage.INDEXEDDB,
})

const store = new Vuex.Store({
	state: {
		loggedInUser: null,
		selectedAppComponent: null,
		selectedApplication: null,
		selectedServiceType: null,
		selectedProject: null,
		selectedRelease: null,
		selectedClientId: null,
		selectedPeakSyncData: null,
		selectedClient: null,
		selectedLiveSync: null,
		selectedUser: null,
		selectedUserType: null,
		selectedRegionMapping: null,
		selectedRegion: null,
		selectedTrainingMaterial: null,
		selectedInfoType: null,
		searchString: null,
		offset: null,
		filter: null,

		locales: null,
		defaultLocale: null,
		selectedLocale: null,
		schema: null,

		packageVersion: process.env.PACKAGE_VERSION || '0'
	},

	mutations: {
		async setLoggedInUser(state, item) {
			state.loggedInUser = item
			await db.setItem('loggedInUser', state.loggedInUser)
		},
		async setSelectedAppComponent(state, item) {
			state.selectedAppComponent = item
			await db.setItem('selectedAppComponent', state.selectedAppComponent)
		},
		async setSelectedApplication(state, item) {
			state.selectedApplication = item
			await db.setItem('selectedApplication', state.selectedApplication)
		},
		async setSelectedServiceType(state, item) {
			state.selectedServiceType = item
			await db.setItem('selectedServiceType', state.selectedServiceType)
		},
		async setSelectedProject(state, item) {
			state.selectedProject = item
			await db.setItem('selectedProject', state.selectedProject)
		},
		async setSelectedRelease(state, item) {
			state.selectedRelease = item
			await db.setItem('selectedRelease', state.selectedRelease)
		},
		async setSelectedClientId(state, item) {
			state.selectedClientId = item
			await db.setItem('selectedClientId', state.selectedClientId)
		},
		async setSelectedPeakSyncData(state, item) {
			state.selectedPeakSyncData = item
			await db.setItem('selectedPeakSyncData', state.selectedPeakSyncData)
		},
		async setSelectedClient(state, item) {
			state.selectedClient = item
			await db.setItem('selectedClient', state.selectedClient)
		},
		async setSelectedLiveSync(state, item) {
			state.selectedLiveSync = item
			await db.setItem('selectedLiveSync', state.selectedLiveSync)
		},
		async setSelectedUser(state, item) {
			state.selectedUser = item
			await db.setItem('selectedUser', state.selectedUser)
		},
		async setSelectedUserType(state, item) {
			state.selectedUserType = item
			await db.setItem('selectedUserType', state.selectedUserType)
		},
		async setSelectedRegionMapping(state, item) {
			state.selectedRegionMapping = item
			await db.setItem('selectedRegionMapping', state.selectedRegionMapping)
		},
		async setSelectedRegion(state, item) {
			state.selectedRegion = item
			await db.setItem('selectedRegion', state.selectedRegion)
		},
		async setSelectedTrainingMaterial(state, item) {
			state.selectedTrainingMaterial = item
			await db.setItem('selectedTrainingMaterial', state.selectedTrainingMaterial)
		},
		async setSelectedInfoType(state, item) {
			state.selectedInfoType = item
			await db.setItem('selectedInfoType', state.selectedInfoType)
		},
		async setSearchString(state, item) {
			state.searchString = item
			await db.setItem('searchString', state.searchString)
		},
		async setOffset(state, item) {
			state.offset = item
			await db.setItem('offset', state.offset)
		},
		async setFilter(state, item) {
			state.filter = item
			await db.setItem('filter', state.filter)
		},
		async setLocales(state, item) {
			state.locales = item
			await db.setItem('locales', state.locales)
		},
		async setDefaultLocale(state, item) {
			state.defaultLocale = item
			await db.setItem('defaultLocale', state.defaultLocale)
		},
		async setSelectedLocale(state, item) {
			state.selectedLocale = item
			await db.setItem('selectedLocale', state.selectedLocale)
		},
		async setSchema(state, item) {
			state.schema = item
			await db.setItem('schema', state.schema)
		},
		async clear() {
			await db.clear()
		},
	},

	actions: {
		getItem(itemName) {
			return this.state[itemName]
		}
	},

	getters: {
		appVersion: (state) => {
			return state.packageVersion
		}
	}
})

store.init = async () => {
	if (db) {
		store.state.loggedInUser = await db.getItem('loggedInUser')
		store.state.selectedAppComponent = await db.getItem('selectedAppComponent')
		store.state.selectedApplication = await db.getItem('selectedApplication')
		store.state.selectedServiceType = await db.getItem('selectedServiceType')
		store.state.selectedProject = await db.getItem('selectedProject')
		store.state.selectedRelease = await db.getItem('selectedRelease')
		store.state.selectedClientId = await db.getItem('selectedClientId')
		store.state.selectedPeakSyncData = await db.getItem('selectedPeakSyncData')
		store.state.selectedClient = await db.getItem('selectedClient')
		store.state.selectedLiveSync = await db.getItem('selectedLiveSync')
		store.state.selectedUser = await db.getItem('selectedUser')
		store.state.selectedUserType = await db.getItem('selectedUserType')
		store.state.selectedRegionMapping = await db.getItem('selectedRegionMapping')
		store.state.selectedRegion = await db.getItem('selectedRegion')
		store.state.selectedTrainingMaterial = await db.getItem('selectedTrainingMaterial')
		store.state.selectedInfoType = await db.getItem('selectedInfoType')
		store.state.searchString = await db.getItem('searchString')
		store.state.offset = await db.getItem('offset')
		store.state.filter = await db.getItem('filter')
		store.state.locales = await db.getItem('locales')
		store.state.defaultLocale = await db.getItem('defaultLocale')
		store.state.selectedLocale = await db.getItem('selectedLocale')
		store.state.schema = await db.getItem('schema')
	}	
}

export default store
