<template>
  <div v-if="show" :class="{backdrop}" @click="backdropClick">
    <div class="dialog-div" :style="`height:${height};width:${width}`">
      <Alert v-model="errorTitle">{{ errorDetail }}</Alert>
      <Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		  <loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

      <v-toolbar elevation="0" color="white" width="100%" class="dialog-header">
        <v-row style="width:100%" align="center">
          <v-toolbar-title style="margin-left:20px"> Setup a New Client</v-toolbar-title>
        </v-row>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider style="border-color:#dedede"/>

      <!-- Select Peak Installation -->
      <v-layout row wrap class="scrollable" v-if="installationView">
        <v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">
          <div class="content">
            <div class="entry">
              <v-row class="pa-3">
                <v-col>
                  <span class="header">Peak Installations</span><br/>
                  <span class="help">Select the PEAK Installation for the new client</span>
				  <v-text-field v-model="metisId" placeholder="Alternatively you may enter a SKISUP id here manually" />
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div v-for="peakInstallation of peakInstallations" :key="peakInstallation.id" 
                :class="selectedPeakInstallation && selectedPeakInstallation.id === peakInstallation.id ? 'selected-row row-divider clickable pa-3' : 'row-divider clickable pa-3'" 
                 @click="selectPeakInstallation(peakInstallation)">
                <v-row class="pa-3">
                  <v-col class="col-2">{{ peakInstallation.installationId }}</v-col>
                  <v-col class="col-7">{{ peakInstallation.customer }}</v-col>
                  <v-col class="col-3">{{ peakInstallation.installationName }}</v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-layout>
  
      <!-- Client Setup Progress -->
      <v-layout row wrap class="scrollable" v-if="!installationView">
        <v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">
          <div class="content">
            <div class="entry" v-if="clientSetup && clientSetup.progress">
              <v-row class="pa-3">
                <v-col>
                  <span class="header">Peak Installation</span><br/>
                  <span class="help">{{ metisId }} {{ selectedPeakInstallation?.installationName }}</span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div v-for="step of clientSetup.progress.data" :key="step.step">
                <v-row class="step" align="center">
                  <div :class="getStepClass(step)">{{step.step}}</div>
                  <span style="margin-left:10px">{{ step.label }}</span>
                  <v-spacer/>
                  <Status :status="step.status" v-if="step.status!=='error'" style="margin-right:10px"/>
                  <v-btn
                    class="ma-rgt"
                    v-if="step.status==='error'" 
                    small
                    color="primary"
                    icon
                    @click="retryFromStep(step.step)"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-row>
                <v-row v-if="step.status==='error'" class="step error pa-3" align="center">
                  {{step.error}}
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-layout>

      <v-divider/>
      <v-toolbar elevation="0" color="white" width="100%" height="60px" class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn class="btn ma-rgt" elevation="0" @click="cancel()">
          <span class="d-none d-md-inline">Cancel</span>
        </v-btn>

        <v-btn id="btnStartSetup" class="btn green" dark elevation="0"  @click="startClientSetup()">
          <span class="d-none d-md-inline">Start Setup</span>
        </v-btn>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ClientSetupWizard',
	components: { Loading, Alert, Status },
	mixins: [ Common ],
	props: {
		height: String,
		width: {
			type: String,
			default: '1100px'
		},
	},
	data: () => ({
		show: false,
		backdrop: true,

		peakInstallations: [],
		selectedPeakInstallation: {},
		clientSetup: {},

		installationView: true,
		timeOut: {},
		metisId: '',
	}),
	computed: {
		isDisabled() {
			return true
		}
	},

	watch: {
		async show(n) {
      this.selectedPeakInstallation = {}

      if (this.show === true) {
        this.installationView = true
        await this.getPeakInstallations()
      }

      this.fixZindexes(n)
		}
	},

  methods: {
    setupDisabled() {
  
      return false
    },
    async getPeakInstallations() {
      this.loading = true
      try {
        this.peakInstallations = await this.$httpGet(`/peak-installations`)
        this.peakInstallations?.sort((a, b) => { return a.customer?.localeCompare(b.customer) })
      } catch (error) {
				this.showError(error)
			}
      
      this.loading = false
    },
	async selectPeakInstallation(item) {
		this.selectedPeakInstallation = item
		this.metisId = item.installationId
		this.loading = true
		try {
			this.installationView = false
			this.clientSetup = await this.$httpGet(`/client-setup/${ this.metisId }`)
		}
		catch (error) {
			this.showError(error)
		}
		this.loading = false
	},
	async startClientSetup() {
		this.loading = true
		if (!this.metisId) this.metisId = this.selectedPeakInstallation.installationId
		try {
			this.installationView = false
			await this.$httpPost(`/client/${ this.metisId }`)
			this.getProgress()
		}
		catch (error) {
			this.showError(error)
		}
		this.loading = false 
	},
	async getProgress() {
		if (this.installationView === false) {
			this.clientSetup = await this.$httpGet(`/client-setup/${ this.metisId }`)
			this.timeOut = setTimeout(this.getProgress, 1000)

			if (this.clientSetup.status === 'error') {
				clearTimeout(this.timeOut)
			}
		}
	},
    getStepClass(step) {
      return `step-block d-flex align-center justify-center ${step.status}`
    },
    cancel() {
      this.show = false
    },
    backdropClick() {
      if (this.closeOnOuterClick) {
        this.show = false
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style['z-index-backedup']) 
            el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		},
		async retryFromStep(stepNumber) {
			this.loading = true
			try {
				await this.$httpPost(`/client/${this.metisId}/retry`, {
					retryFromStep: stepNumber
				})
				this.getProgress()
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		}
	},
	beforeDestroy () {
		this.fixZindexes(false)
	},
}
</script>

<style scoped lang="scss">
.backdrop { position: fixed; height: calc(100vh - 56px); width: 100%; background: rgba(0, 0, 0, 0.5); z-index: 999; top: 56px; left: 0; }
.scrollable { overflow-y: auto; overflow-x: hidden; }
#btnStartSetup.v-btn--disabled { background-color: #58b01e !important; }

.step { border: 1px solid #dcdcdc; border-radius: 5px; height: 60px; margin: 10px; }
.step-block { padding:0; margin:0; height:100%; width:50px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; background-color:#dcdcdc; text-align: center; font-size: 20px; }
.in_progress { background-color: #ff9e21; color: #ffffff; }
.pending { background-color: #dcdcdc; color: #ffffff; }
.done { background-color: #66BB6A; color: #ffffff }
.error { background-color: #f34545; color: #ffffff}

.content { top: 64px !important; width: 100%; height: calc(90vh - 126px) !important; padding: 20px 20px 20px 20px; overflow: hidden; }
.entry { border: 1px solid #c0c0c0; max-height: calc(90vh - 156px); border-radius: 5px; overflow-y: scroll;}
.header { color: gray; font-size:13pt; text-transform: capitalize; }

.view { display: flex; flex-direction: column; height: 100%; }
.table-container { display: flex; flex-grow: 1; overflow: hidden; }
.flex-table { display: flex; flex-grow: 1; }
.selected-row { background-color: #0ba7e1; color: #ffffff; }
.clickable { cursor:pointer }
.row-divider { border-bottom: 1px solid rgba(0,0,0,.12); }

.wiz-sidebar {
  top: 64px !important;
  height: calc(90vh - 126px) !important;
  background-color: transparent !important;
  z-index: 4 !important;

  ::v-deep .v-navigation-drawer__content {
    height: calc(90vh - 126px) !important;
    padding: 10px 0px 10px 0px;
  }

  ::v-deep #navlist li {
    padding-left: 0;
  }
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;
  }
}
</style>
