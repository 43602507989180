<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Filters -->
				<div style="padding-left:20px;padding-right:20px">
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template #activator="{ on }">
							<v-row justify="end" v-on="on">
								<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
								<v-btn class="btn" elevation="0" x-small>
									<v-icon>mdi-filter</v-icon>
									<span class="d-none d-md-inline">Filter</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">Clients</span>
										<v-select filled solo class="btn"
											ref="filterClients"
											v-model="selectedClients"
											:items="clients"
											:item-text="item => item.fields.title.de"
											:item-value="item => item.sys.id"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip>{{item.fields.title.de}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<!-- Invalid Region Filter -->
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<span style="width:100%">Region Filter</span>
									<v-checkbox
										v-model="showInvalidRegionsOnly"
										label="Show only missing/invalid regions"
										hide-details
									/>
									<div class="filter-description">
										Filters to show only region mappings that have missing, deleted, or invalid region references
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<v-row class="pl-6 pr-6 pb-4 pt-6">
								<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
								<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>
				
				<!-- Add New Region Mapping -->
				<v-btn class="btn" small elevation="0" @click="showNewRegionMapping()">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">Add Region Mapping</span>
				</v-btn>
			</v-row>
		</div>


		<!-- Content -->
		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort 
				v-model="selected"
				:headers="headers"
				:items="regionMappings"
				:items-per-page="limit"
				item-key="sys.id"
				class="elevation-0"
				@click:row="showRegionMappingDetail">
				
				<template #[`item.created_at`]="{ item }">
					{{ item.sys.createdAt | formatShortDate }}
				</template>
				<template #[`item.updated_at`]="{ item }">
					{{ item.sys.updatedAt | formatShortDate }}
				</template>
				<template #[`item.regionStatus`]="{ item }">
					<v-chip v-if="hasInvalidRegion(item)" color="error" small>
						<v-icon small left>mdi-alert-circle</v-icon>
						Missing Region
					</v-chip>
					<span v-else-if="item.fields?.region?.de?.fields" class="region-name">
						{{ item.fields.region.de.fields.title.de || '' }}
					</span>
					<span v-else class="region-name">
						Unknown Region
					</span>
				</template>
			</v-data-table>
			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="itemCount" @input="getRegionMappings()" :results="regionMappings" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: 'RegionMappings',
	components: { Loading, Alert, TablePaginator },
	mixins: [ Common ],

	data() {
		return {
			Action: Action, 

			offset: this.$store.state.offset ? this.$store.state.offset : 0,
			searchString: this.$store.state.searchString ? this.$store.state.searchString : '',

			selected: [],
			singleSelect: '',
			regionMappings: [],
			itemCount: 0,
			limit: 15,

			isOpened: false,
			selectedClients: this.filter ? this.filter.selectedClients : [],
			clients: [],
			showInvalidRegionsOnly: false
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Name', value: 'fields.title.de', cellClass: 'truncate', width: '20%' },
				{ text: 'Client', value: 'fields.client.de.fields.title.de', cellClass: 'truncate', width: '20%' },
				{ text: 'Region', value: 'regionStatus', align: 'center', width: '15%' },
				{ text: 'Min ZIP Code' , value: 'fields.minZipCode.de', align:'center', width: '15%' },
				{ text: 'Max ZIP Code' , value: 'fields.maxZipCode.de', align:'center', width: '15%' },
				{ text: 'Created', value: 'created_at', width: '150' },
				{ text: 'Updated', value: 'updated_at', width: '150' },
			]
		},
	},

	async mounted() {
		await this.getClients()
		this.getRegionMappings()
	},

	methods: {
		async getClients() {
			try {
				const res = await this.$httpGet('/clients')
				this.clients = res.clients
			} catch (error) {
				this.showError(error)
			}
		},
		async getRegionMappings() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				
				// Create filter object
				const filter = this.$store.state.filter ? { ...this.$store.state.filter } : {}
				
				// Add missing region filter if selected
				if (this.showInvalidRegionsOnly) {
					filter.missingRegion = true
				}
				
				const url = `/region-mappings?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&filter=${JSON.stringify(filter)}`
				
				const res = await this.$httpGet(url)

				this.regionMappings = res.regionMappings
				this.itemCount = res.total
				
				// add an overall min and max ZIP code for each region mapping to show it in the table
				let minimumZipCodes = []
				let maximumZipCodes = []
				this.regionMappings.forEach(regionMapping => {
					regionMapping.fields.zipCodes.de.forEach(zipCode => {	
						minimumZipCodes.push(zipCode.zipMin)
						maximumZipCodes.push(zipCode.zipMax)
					})
					regionMapping.fields['minZipCode'] = { de: Math.min(...minimumZipCodes) }
					regionMapping.fields['maxZipCode'] = { de: Math.max(...maximumZipCodes) }
					minimumZipCodes = []
					maximumZipCodes = []
				})
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
			this.loading = false
		},
		async showRegionMappingDetail(item) {
			await this.$store.commit('setSelectedRegionMapping', item)
			this.$router.push(`/regionmapping-detail?action=${Action.VIEW}`)
		},
		async showNewRegionMapping() {
			await this.$store.commit('setSelectedRegionMapping', null)
			this.$router.push(`/regionmapping-detail?action=${Action.CREATE}`)
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
				
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getRegionMappings()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getRegionMappings()
		},
		async applyFilter() {
			this.isOpened = false
			this.offset = 0
			const filter = {
				clients: this.selectedClients,
				missingRegion: this.showInvalidRegionsOnly
			}

			await this.$store.commit('setFilter', filter)
			await this.getRegionMappings()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedClients = []
			this.showInvalidRegionsOnly = false
			this.offset = 0

			await this.$store.commit('setFilter', null)
			this.getRegionMappings()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterClients.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterClients.blur()
				this.dropdownOpen = false
			}
		},
		hasInvalidRegion(regionMapping) {
			// Case 0: Region field doesn't exist at all
			if (!regionMapping.fields || !regionMapping.fields.region) return true
			
			const regionRef = regionMapping.fields.region.de
			
			// Case 1: Region reference is empty or undefined
			if (!regionRef) return true
			
			// Case 2: Region has an explicit error property
			if (regionRef.error) return true
			
			// Case 3: Region has a sys.id but no fields (reference to deleted region)
			if (regionRef.sys && !regionRef.fields) return true
			
			// Case 4: Region reference exists but fields.title doesn't exist
			if (!regionRef.fields?.title?.de) return true
			
			return false
		}
	}
}
</script>

<style scoped>
.region-name {
	color: #2c3e50;
	font-size: 0.875rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
	display: inline-block;
	padding: 0 12px;
	height: 24px;
	border-radius: 16px;
	background-color: #f5f5f5;
	line-height: 24px;
	margin-right: 8px;
	margin-bottom: 4px;
}

.filter-description {
	font-size: 12px;
	color: #666;
	margin-top: 4px;
	margin-left: 30px;
	font-style: italic;
}
</style>
