<template>
	<v-container fluid class="pa-2 main gridContainer">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
	
		<div class="toolbar">
			<v-row align="center">
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search by name, key or ID"
					class="mr-3"
					dense
					outlined
					hide-details
					@input="onSearchInput"
				></v-text-field>
				<v-btn class="btn blue" elevation="0" dark @click="addInfoType()">
					<v-icon dark>mdi-plus</v-icon>
					&nbsp;Add Info Type
				</v-btn>
			</v-row>
		</div>

		<v-data-table
			:headers="headers"
			:items="infoTypes"
			:page.sync="page"
			item-key="id"
			:items-per-page="itemsPerPage"
			hide-default-footer
			class="customTable"
			@click:row="showDetails"
			:loading="loading"
			:server-items-length="infoTypesCount"
			loading-text="Loading... Please wait"
		>
			<template #no-data>
				<div class="text-center">
					<h5>No info types found</h5>
				</div>
			</template>

			<template #[`item.title`]="{ item }">
				<div>{{ item.name?.de || item.name?.en }}</div>
			</template>

			<template #[`item.key`]="{ item }">
				<v-label>{{ item.key }}</v-label>
			</template>

			<template #[`item.createdDate`]="{ item }">
				<div>{{ formatDate(item.createdAt) }}</div>
			</template>

			<template #[`item.icon`]="{ item }">
				<div v-if="item.icon">
					<v-avatar size="36">
						<v-img :src="item.icon" alt="Icon"></v-img>
					</v-avatar>
				</div>
				<div v-else>
					<v-icon>mdi-help-circle-outline</v-icon>
				</div>
			</template>
		</v-data-table>

		<div class="text-center pt-2 paginator">
			<v-pagination v-model="page" :length="pageCount" @input="onPageChange"></v-pagination>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'
import moment from 'moment'

export default {
	name: 'InfoTypesView',
	components: { Loading, Alert },
	mixins: [Common],

	data() {
		return {
			search: '',
			searchTimeout: null,
			page: 1,
			itemsPerPage: 10,
			infoTypesCount: 0,
			infoTypes: [],
			allInfoTypes: [],

			headers: [
				{ text: 'Title', value: 'title', sortable: false },
				{ text: 'Key (Content Hub ID)', value: 'key', sortable: false },
				{ text: 'Icon', value: 'icon', sortable: false },
				{ text: 'Created', value: 'createdDate', sortable: false }
			]
		}
	},

	computed: {
		pageCount() {
			return Math.ceil(this.infoTypesCount / this.itemsPerPage)
		}
	},

	created() {
		// Reset any previously stored search parameters
		this.search = ''
		this.page = 1
	},

	mounted() {
		this.getInfoTypes()
	},

	beforeRouteEnter(to, from, next) {
		// This ensures data is fetched when navigating to this route
		next(vm => {
			vm.getInfoTypes()
		})
	},

	methods: {
		async getInfoTypes() {
			if (this.loading) return
			
			this.loading = true

			try {
				// Call our new controller endpoint instead of API2 directly
				const response = await this.$httpGet('/info-types')
				
				// Store all info types for client-side filtering
				this.allInfoTypes = response || []
				
				// Apply client-side filtering and pagination
				this.filterAndPaginateInfoTypes()
			} catch (error) {
				this.showError(error)
			}

			this.loading = false
		},

		filterAndPaginateInfoTypes() {
			// Apply search filter if needed
			let filteredInfoTypes = this.allInfoTypes
			
			if (this.search) {
				const searchLower = this.search.toLowerCase()
				filteredInfoTypes = this.allInfoTypes.filter(infoType => {
					// Search in title
					const titleMatch = Object.values(infoType.name || {}).some(value => 
						value && value.toLowerCase().includes(searchLower)
					)
					
					// Search in key or ID
					const keyMatch = infoType.key && infoType.key.toLowerCase().includes(searchLower)
					const idMatch = infoType.id && infoType.id.toLowerCase().includes(searchLower)
					
					return titleMatch || keyMatch || idMatch
				})
			}
			
			// Update total count
			this.infoTypesCount = filteredInfoTypes.length
			
			// Apply pagination
			const start = (this.page - 1) * this.itemsPerPage
			const end = start + this.itemsPerPage
			this.infoTypes = filteredInfoTypes.slice(start, end)
		},

		onPageChange() {
			// Apply pagination client-side instead of getting new data
			this.filterAndPaginateInfoTypes()
		},

		onSearchInput() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
			}

			this.searchTimeout = setTimeout(() => {
				this.page = 1 // Reset to first page on new search
				this.filterAndPaginateInfoTypes()
			}, 300)
		},

		addInfoType() {
			this.$router.push({
				path: '/info-type-detail',
				query: { action: 'CREATE' }
			})
		},

		showDetails(infoType) {
			this.$store.commit('setSelectedInfoType', infoType)
			this.$router.push({
				name: 'InfoTypeDetailDeepUrl',
				params: { id: infoType.id }
			})
		},

		formatDate(date) {
			return moment(date).format('DD.MM.YYYY HH:mm:ss')
		}
	}
}
</script>

<style scoped>
.toolbar {
	margin-top: 20px;
	margin-bottom: 10px;
}

.customTable {
	cursor: pointer;
}

.paginator {
	margin-top: 20px;
}
</style> 