<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ action === this.Action.CREATE ? 'Create New Info Type' : 'Info Type Detail' }}</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block" v-if="userMayEditFields()">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="saveInfoType()">Save Info Type</v-btn>
					<v-btn 
						v-if="action !== this.Action.CREATE" 
						block 
						class="btn red mt-3" 
						elevation="0" 
						dark 
						@click="confirmDelete()"
					>
						<v-icon left>mdi-delete</v-icon>
						Delete Info Type
					</v-btn>
				</div>
			</div>

			<div class="sidebar-block" v-if="action !== this.Action.CREATE">
				<p class="sidebar-title">Info</p>
				<div class="mb-2">
					<div class="d-flex align-center mb-1">
						<span style="min-width: 40px">id:</span>
						<v-label v-if="infoType?.id" class="ml-2">{{ infoType.id }}</v-label>
						<v-btn small icon elevation="0" class="ml-auto" @click="copyIdToClipboard()">
							<v-icon small>mdi-content-copy</v-icon>
						</v-btn>
					</div>
					<div class="d-flex align-center">
						<span style="min-width: 40px">key:</span>
						<v-label v-if="infoType?.key" class="ml-2">{{ infoType.key }}</v-label>
						<v-btn small icon elevation="0" class="ml-auto" @click="copyKeyToClipboard()">
							<v-icon small>mdi-content-copy</v-icon>
						</v-btn>
					</div>
				</div>
				<!-- copy id & key to clipboard -->
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="true" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Info Type Name</v-label>
					<div v-for="locale in locales" :key="locale.code" class="mb-4">
						<div class="d-flex align-center">
							<div class="d-flex align-center" style="width: 10%">
								<LanguageFlag :value="locale.code" class="mr-2" />
								<v-label>{{ locale.name }}</v-label>
							</div>
							<div style="width: 90%">
								<v-text-field 
									outlined 
									hide-details 
									:disabled="!userMayEditFields()"
									v-model="infoType.name[locale.code]"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="field left-border mt-6">
					<v-label>Icon</v-label>
					<div class="d-flex align-center mt-2">
						<div v-if="iconPreview" class="mr-4">
							<v-avatar size="64">
								<v-img :src="iconPreview" alt="Icon Preview"></v-img>
							</v-avatar>
						</div>
						<div v-else-if="infoType.icon" class="mr-4">
							<v-avatar size="64">
								<v-img :src="infoType.icon" alt="Icon"></v-img>
							</v-avatar>
						</div>
						<div v-else class="mr-4">
							<v-avatar size="64" color="grey lighten-2">
								<v-icon size="48" color="grey darken-2">mdi-image-outline</v-icon>
							</v-avatar>
						</div>
						
						<div>
							<v-file-input
								:disabled="!userMayEditFields()"
								v-model="iconFile"
								accept="image/*"
								label="Upload Icon"
								prepend-icon="mdi-image"
								outlined
								dense
								hide-details
								@change="previewIcon"
							></v-file-input>
							<div class="caption mt-1 grey--text">
								Recommended size: 128x128px. Supported formats: JPG, PNG, SVG.
							</div>
						</div>
					</div>
				</div>
			</Disclosure>
		</div>

		<!-- Audit Log -->
		<div v-if="action !== this.Action.CREATE && infoType.id" class="pa-3" style="width: 100%">
			<AuditLog :entryId="infoType.id" :contentType="'info-type'"/>
		</div>

		<!-- Delete Confirmation Dialog -->
		<v-dialog v-model="showDeleteDialog" max-width="500px">
			<v-card>
				<v-card-title class="text-h5">
					<v-icon large color="error" class="mr-2">mdi-alert-circle</v-icon>
					Delete Info Type
				</v-card-title>
				<v-card-text>
					Are you sure you want to delete this info type? This action cannot be undone. <b>The info type will be removed from the MYS DB and (eventually) from all Content Hub spaces.</b>
					<p class="mt-2">
						<strong>Name:</strong> {{ infoType.name?.de }}
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="showDeleteDialog = false">
						Cancel
					</v-btn>
					<v-btn 
						color="error" 
						@click="deleteInfoType()"
					>
						Delete
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Common from '@/mixins/Common.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import axios from 'axios'
import _ from 'lodash'

export default {
	name: "InfoTypeDetailView",
	components: { 
		Loading, 
		SideBar, 
		Alert, 
		Disclosure, 
		AuditLog,
		LanguageFlag
	},
	mixins: [ Common ],

	props: {
		initialAction: { type: String },
		infoTypeId: { type: String }
	},

	data() {
		return {
			Action: {
				CREATE: 'CREATE',
				VIEW: 'VIEW',
				EDIT: 'EDIT'
			},
			action: this.initialAction,
			infoType: {
				id: '',
				name: {
					de: '',
					en: '',
					fr: '',
					it: '',
					nl: ''
				},
				key: '',
				icon: null
			},
			iconFile: null,
			iconPreview: null,
			runValidation: false,
			showDeleteDialog: false
		}
	},
	
	computed: {
	},

	async mounted() {
		this.loading = true
		
		// Get locales if not already available
		if (!this.locales?.length) {
			await this.getLocales()
		}
		
		// For direct access by ID or edit mode, get the info type details
		if (this.infoTypeId) {
			// Direct access via URL with ID
			this.action = this.Action.VIEW
			await this.getInfoTypeDetailsById(this.infoTypeId)
		} else if (this.action === this.Action.VIEW) {
			// Access via store selection
			await this.getInfoTypeDetails()
		}
		
		this.loading = false
	},

	methods: {
		async getInfoTypeDetailsById(id) {
			try {
				// Use our controller endpoint instead of calling API2 directly
				const infoType = await this.$httpGet(`/info-types/${id}`)
				
				this.infoType = infoType
				
				// Also update the store
				this.$store.commit('setSelectedInfoType', this.infoType)
			} catch (error) {
				this.showError(error)
				// Navigate back to list on error
				setTimeout(() => this.goback(), 2000)
			}
		},
		
		async getInfoTypeDetails() {
			if (!this.$store.state.selectedInfoType?.id) return
			
			try {
				// Use our controller endpoint instead of calling API2 directly
				const id = this.$store.state.selectedInfoType.id
				this.infoType = await this.$httpGet(`/info-types/${id}`)
			} catch (error) {
				this.showError(error)
			}
		},
		
		previewIcon(file) {
			if (!file) {
				this.iconPreview = null
				return
			}
			
			// Create a preview URL for the selected file
			this.iconPreview = URL.createObjectURL(file)
		},
		
		async uploadIcon() {
			if (!this.iconFile) return null
			
			try {
				// Create form data for file upload
				const formData = new FormData()
				formData.append('file', this.iconFile)
				
				// Upload the file to S3
				const uploadResponse = await axios.post(
					'/upload-to-s3',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							...this.requestHeaders()
						}
					}
				)
				
				// Return the S3 URL from the response
				return uploadResponse.data.url
			} catch (error) {
				this.showError(error)
				return null
			}
		},
		
		async saveInfoType() {
			if (!this.infoType.name.de) {
				this.errorTitle = 'VALIDATION ERROR'
				this.errorDetail = 'Please enter the required information'
				return
			}
			
			this.loading = true
			
			try {
				// Upload icon if a new one was selected
				let iconUrl = this.infoType.icon
				if (this.iconFile) {
					iconUrl = await this.uploadIcon()
				}
				
				const payload = _.cloneDeep(this.infoType)
				if (iconUrl) {
					payload.icon = iconUrl
				}
				
				let response
				if (this.action === this.Action.CREATE) {
					response = await this.$httpPost('/info-types', payload)
				} else {
					response = await this.$httpPut(`/info-types/${this.infoType.id}`, payload)
				}
				
				// Update store and local state
				this.$store.commit('setSelectedInfoType', response)
				this.infoType = response
				
				// Update action to VIEW if it was CREATE
				if (this.action === this.Action.CREATE) {
					this.action = this.Action.VIEW
					// Update URL
					this.$router.replace({
						path: '/info-type-detail',
						query: { action: 'VIEW' }
					})
				}
				
				this.successTitle = 'SUCCESS'
				this.successDetail = 'Info type saved successfully'
				
				// Reset icon file input after successful upload
				this.iconFile = null
				this.iconPreview = null
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		
		confirmDelete() {
			this.showDeleteDialog = true
		},
		
		async deleteInfoType() {
			this.loading = true
			this.showDeleteDialog = false
			
			try {
				// Use our controller endpoint for deletion
				// Encode the icon URL to ensure it's properly passed
				const iconParam = this.infoType.icon ? encodeURIComponent(this.infoType.icon) : ''
				await this.$httpDelete(`/info-types/${this.infoType.id}?icon=${iconParam}`)
				
				this.successTitle = 'SUCCESS'
				this.successDetail = 'Info type deleted successfully'
				
				setTimeout(() => { this.goback() }, 2000)
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		
		goback() {
			this.$router.push('/info-types')
		},
		
		copyKeyToClipboard() {
			if (this.infoType.key) {
				navigator.clipboard.writeText(this.infoType.key)
			}
		},

		copyIdToClipboard() {
			if (this.infoType.id) {
				navigator.clipboard.writeText(this.infoType.id)
			}
		}
	}
}
</script>

<style scoped>
.sidebar-block {
	padding: 16px;
	border-bottom: 1px solid #e0e0e0;
}

.sidebar-title {
	font-weight: bold;
	margin-bottom: 10px;
}

.buttonBlock {
	margin-top: 16px;
}

.mandatory {
	color: red;
}

</style> 